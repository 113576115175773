<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
     <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class=" elevation-1">
            Trending Details
            <v-spacer></v-spacer>
          </v-card-title>
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Link</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.link}}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Title</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.title }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Caption</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.caption }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Photo</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <v-img  :src="baseURL + user.image"
                          width="150px"
                          height="150px" >
               </v-img>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              
            </v-row>
          </div>
          <v-row class="justify-center">
            <div class="mt-5">
              <template>
                <v-dialog v-model="dialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" outlined color="black"
                      >Remove Trending</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete this Trending?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="deleteuser()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </div>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["trendingid"],
  data() {
    return {
        menu:false,
      date: new Date().toISOString().substr(0, 10),
      caption: "",
      title: "",
      file: null,
      dialog:false,
      checkbox: true,
      showPreview: false,
      imagePreview: null,
      appLoading: false,
      showsnackbar: false,
      link: null,
      photo: null,
      user: [],
      msg: null,
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/trending/get",
        method: "GET",
        params: {
          id: this.trendingid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this. user = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["link"] = this.link;
      user["title"] = this.title;
      user["caption"] = this.caption;
      user["expirytime"] = this.date;
      user["id"] = this.trendingid;
      axios({
        method: "POST",
        url: "/home/trending/edit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.file) {
              this.uploadImage();
            } else {
              this.getData();
              this.$router.push("/home/exclusiveList");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      // var files=e.target.files
      this.file = e.target.files[0];

      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      //reader.readAsDataURL(files[0]);

      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("id", this.trendingid);
      formData.append("photo", this.file);

      axios({
        method: "POST",
        url: "/home/trending/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$router.push("/home/exclusiveList");
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     deleteuser() {
      axios({
        url: "/home/trending/remove",

        method: "GET",
        params: {
          id: this.trendingid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>